import { EFlowType, FlowStatus } from '../model/enum';
import { IAllowedFlows, IPaths } from '../model';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import Actions from '../pages/Actions/Actions';
import Calendaring from '../pages/Calendaring/Calendaring';
import { ELanguages } from '../localization/index';
import EditAction from '../pages/Actions/EditAction';
import EditCalendaring from '../pages/Calendaring/EditCalendaring';
import EditFLow from '../pages/Flows/EditFlow';
import EditRole from '../pages/Roles/EditRole';
import EditStructure from '../pages/PersonalDataStructure/EditStructure';
import EditUser from '../pages/Users/EditUser';
import ErrorBoundary from './ErrorBoundary';
import Flows from '../pages/Flows/Flows';
import GenericFlow from '../componets/GenericFlow/GenericFlow';
import Home from '../pages/Home';
import Layout from '../layout/Layout';
import LogPage from '../componets/Logs/LogPage';
import Msg from '../pages/Msg';
import NewAction from '../pages/Actions/NewAction';
import NewFlow from '../pages/Flows/NewFlow';
import NewRole from '../pages/Roles/NewRole';
import NewStructures from '../pages/PersonalDataStructure/NewStructures';
import NewUser from '../pages/Users/NewUser';
import NotFound from './NotFound';
import OverviewFactory from '../componets/OverviewFactory/OverviewFactory';
import OverviewSdoSdoR from '../pages/SDO/OverviewSdoSdoR';
import { ReactNode } from 'react';
import Roles from '../pages/Roles/Roles';
import SendFiles from '../pages/SDO/SendFiles';
import Structures from '../pages/PersonalDataStructure/Structures';
import Users from '../pages/Users/Users';
import { isSiag } from '../utils';

export const route = {
    notFound: '*',
    home: '/',
    roles: 'ruoli',
    logPage: 'log',
    actions: 'azioni',
    flows: 'flussi',
    sendFile: 'invia-nuovi-file',
    overview: 'panoramica',
    errorCodes: 'codici-errori',
    dataExtraction: 'estrazione-dati',
    logout: 'logout',
    structures: 'strutture',
    users: 'utenti',
    calendaring: 'calendarizzazione',
    new: 'nuovo',
    edit: 'modifica',
    msg: 'notify',
    sendFilesSiar: 'siar',
    overviewSiar: 'overview-siar',
    flow1: 'flow1',
};

enum Area {
    SETTINGS,
    RICOVERI,
    SPECIALISTICA,
    FARMACEUTICA,
    EMERGENZAURGENZA,
    REGISTRIPATOLOGIA,
    DISPOSITIVI,
}

const areaByName = {
    [EFlowType.Sdo.toUpperCase()]: Area.RICOVERI,
    [EFlowType.Fim.toUpperCase()]: Area.RICOVERI,
    [EFlowType.Cedp.toUpperCase()]: Area.RICOVERI,
    [EFlowType.Spa.toUpperCase()]: Area.SPECIALISTICA,
    [EFlowType.Sderia.toUpperCase()]: Area.RICOVERI,
    [EFlowType.Farmter.toUpperCase()]: Area.FARMACEUTICA,
    [EFlowType.Sps.toUpperCase()]: Area.EMERGENZAURGENZA,
    [EFlowType.Diab.toUpperCase()]: Area.REGISTRIPATOLOGIA,
    [EFlowType.Farm.toUpperCase()]: Area.FARMACEUTICA,
    [EFlowType.Rpa.toUpperCase()]: Area.REGISTRIPATOLOGIA,
    [EFlowType.Rpg.toUpperCase()]: Area.REGISTRIPATOLOGIA,
    [EFlowType.Hosp.toUpperCase()]: Area.RICOVERI,
    [EFlowType.X.toUpperCase()]: Area.EMERGENZAURGENZA,
    [EFlowType.Farmosp.toUpperCase()]: Area.FARMACEUTICA,
    [EFlowType.Labo.toUpperCase()]: Area.SPECIALISTICA,
    [EFlowType.Dm.toUpperCase()]: Area.DISPOSITIVI,
    [EFlowType.Sdor.toUpperCase()]: Area.RICOVERI,
    [EFlowType.Siar.toUpperCase()]: Area.RICOVERI,
};

const createRoute = (path: string, element: React.ReactNode) => ({
    path,
    element,
    errorElement: <ErrorBoundary />,
});
const defaultRoute: RouteObject = {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
};
const GENERIC_CHILDREN = [createRoute(route.home, <Home />), createRoute(route.msg, <Msg />)];

const SIAG_BACK_OFFICE = [
    createRoute(route.logPage, <LogPage />),
    createRoute(route.roles, <Roles />),
    createRoute(route.roles + route.home + route.new, <NewRole />),
    createRoute(route.roles + route.home + ':id', <EditRole />),
    createRoute(route.actions, <Actions />),
    createRoute(route.actions + route.home + route.new, <NewAction />),
    createRoute(route.actions + route.home + ':id', <EditAction />),
    createRoute(route.flows, <Flows />),
    createRoute(route.flows + route.home + route.new, <NewFlow />),
    createRoute(route.flows + route.home + ':id', <EditFLow />),
    createRoute(route.structures, <Structures />),
    createRoute(route.structures + route.home + route.new, <NewStructures />),
    createRoute(route.structures + route.home + ':id', <EditStructure />),
    createRoute(route.users, <Users />),
    createRoute(route.users + route.home + route.new, <NewUser />),
    createRoute(route.users + route.home + ':id', <EditUser />),
    createRoute(route.calendaring, <Calendaring />),
    createRoute(route.calendaring + route.home + route.new, <EditCalendaring />),
    createRoute(route.calendaring + route.home + ':id' + route.home + ':flowId' + route.home + ':roleId', <EditCalendaring />),
];
const SDO = [createRoute(route.sendFile, <SendFiles />), createRoute(route.overview, <OverviewSdoSdoR />)];

const buildFlowRoute = (
    name: string,
): {
    path: string;
    element: ReactNode;
    errorElement: JSX.Element;
}[] => {
    const routes = [createRoute(name.toLowerCase(), <GenericFlow flowType={name} key={name} />)];

    if (name.toUpperCase() !== EFlowType.Farmter.toUpperCase()) {
        routes.push(createRoute(`overview-${name.toLowerCase()}`, <OverviewFactory flowType={name as EFlowType} key={name} />));
    }

    return routes;
};

export const buildPaths = (paths: IAllowedFlows[], role: string) => {
    const userPaths = paths
        .filter(p => p.isAllowed && (p.flowStatus === FlowStatus.OracleManaged || p.flowStatus === FlowStatus.Remade))
        .map(p => {
            if (p.name === 'SDO' || p.name === 'SDO-R') return [...SDO];
            else {
                return buildFlowRoute(p.name);
            }
        })
        .flat(1);

    if (isSiag(role)) {
        return createBrowserRouter([
            {
                ...defaultRoute,
                children: [...GENERIC_CHILDREN, ...SIAG_BACK_OFFICE, ...userPaths, createRoute(route.notFound, <NotFound />)],
            },
        ]);
    }

    return createBrowserRouter([
        {
            ...defaultRoute,
            children: [...GENERIC_CHILDREN, ...userPaths, createRoute(route.notFound, <NotFound />)],
        },
    ]);
};

const itLink = 'https://osservatorio-salute.provincia.bz.it/it/scheda-dimissione-ospedaliera';
const deLink = 'https://gesundheitsbeobachtung.provinz.bz.it/de/krankenhausentlassungsbogen-keb';

export const getLinkByRole = (language: string, paths: IAllowedFlows[], isSiag: boolean): IPaths[] => {
    const BACK_OFFICE = [
        {
            mainName: 'routes.permissions',
            area: Area.SETTINGS,
            children: [
                { name: 'routes.roles', path: route.roles, key: route.roles },
                { name: 'routes.actions', path: route.actions, key: route.actions },
                { name: 'routes.flows', path: route.flows, key: route.flows },
                { name: 'routes.logPage', path: route.logPage, key: route.logPage },
            ],
        },
        {
            mainName: 'routes.personalData',
            area: Area.SETTINGS,
            children: [
                { name: 'routes.structures', path: route.structures, key: route.structures },
                { name: 'routes.users', path: route.users, key: route.users },
            ],
        },
        {
            mainName: 'routes.calendaring',
            area: Area.SETTINGS,
            children: [],
            path: route.calendaring,
        },
    ];
    const SDO = {
        mainName: 'routes.sdo',
        area: Area.RICOVERI,
        children: [
            { name: 'routes.overview', path: route.overview, key: route.overview },
            { name: 'routes.sendFile', path: route.sendFile, key: route.sendFile },
            { name: 'routes.ministryLink', path: language === ELanguages.it ? itLink : deLink, key: language === ELanguages.it ? itLink : deLink, isExternal: true },
        ],
    };

    let userPaths: IPaths[] = [];

    paths.forEach(p => {
        if (p.isAllowed && (p.flowStatus === FlowStatus.OracleManaged || p.flowStatus === FlowStatus.Remade)) {
            switch (p.name.toUpperCase()) {
                case 'SDO-R':
                case 'SDO':
                    if (userPaths.some(data => data.mainName === 'routes.sdo')) {
                        userPaths = [...userPaths];
                    } else {
                        userPaths = [...userPaths, SDO];
                    }
                    break;
                case 'FARMTER':
                    userPaths = [
                        ...userPaths,
                        {
                            mainName: `routes.${p.name.toLowerCase()}`,
                            area: Area.FARMACEUTICA,
                            children: [{ name: 'routes.sendFile', path: p.name.toLowerCase(), key: p.name.toLowerCase() }],
                        },
                    ];
                    break;
                default:
                    userPaths = [
                        ...userPaths,
                        {
                            mainName: `routes.${p.name.toLowerCase()}`,
                            area: areaByName[p.name.toUpperCase()],
                            children: [
                                { name: 'routes.overview', path: `overview-${p.name.toLowerCase()}`, key: `overview-${p.name.toLowerCase()}` },
                                { name: 'routes.sendFile', path: p.name.toLowerCase(), key: p.name.toLowerCase() },
                            ],
                        },
                    ];
                    break;
            }
        }
    });

    userPaths.sort((a, b) => {
        const nameA = a.area;
        const nameB = b.area;
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    if (isSiag) return [...BACK_OFFICE, ...userPaths];

    return userPaths;
};
