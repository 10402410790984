import { IOrderObject, IOverview118, IOverviewBE, IOverviewDm, IOverviewFarm, IOverviewLabo, IOverviewPatology, IOverviewSpa, IOverviewSps, LogItem } from '../model';

export const normalizeUseRecoveryOverview = (obs: IOverviewBE[]) => {
    if (obs && Array.isArray(obs)) {
        return obs.map(item => {
            const normalizedItem = { ...item };

            // year
            if (normalizedItem.currentYearRow.year === null || normalizedItem.currentYearRow.year === undefined) {
                normalizedItem.currentYearRow = {
                    ...normalizedItem.currentYearRow,
                    year: '',
                };
            }
            if (normalizedItem.lastYearRow.year === null || normalizedItem.lastYearRow.year === undefined) {
                normalizedItem.lastYearRow = {
                    ...normalizedItem.lastYearRow,
                    year: '',
                };
            }

            if (normalizedItem.yearsDifferences.year === null || normalizedItem.yearsDifferences.year === undefined) {
                normalizedItem.yearsDifferences = {
                    ...normalizedItem.yearsDifferences,
                    year: '',
                };
            }

            // structureNameDe
            if (normalizedItem.currentYearRow.structureNameDe === null || normalizedItem.currentYearRow.structureNameDe === undefined) {
                normalizedItem.currentYearRow = {
                    ...normalizedItem.currentYearRow,
                    structureNameDe: '',
                };
            }
            if (normalizedItem.lastYearRow.structureNameDe === null || normalizedItem.lastYearRow.structureNameDe === undefined) {
                normalizedItem.lastYearRow = {
                    ...normalizedItem.lastYearRow,
                    structureNameDe: '',
                };
            }

            if (normalizedItem.yearsDifferences.structureNameDe === null || normalizedItem.yearsDifferences.structureNameDe === undefined) {
                normalizedItem.yearsDifferences = {
                    ...normalizedItem.yearsDifferences,
                    structureNameDe: '',
                };
            }
            // structureNameIt
            if (normalizedItem.currentYearRow.structureNameIt === null || normalizedItem.currentYearRow.structureNameIt === undefined) {
                normalizedItem.currentYearRow = {
                    ...normalizedItem.currentYearRow,
                    structureNameIt: '',
                };
            }
            if (normalizedItem.lastYearRow.structureNameIt === null || normalizedItem.lastYearRow.structureNameIt === undefined) {
                normalizedItem.lastYearRow = {
                    ...normalizedItem.lastYearRow,
                    structureNameIt: '',
                };
            }

            if (normalizedItem.yearsDifferences.structureNameIt === null || normalizedItem.yearsDifferences.structureNameIt === undefined) {
                normalizedItem.yearsDifferences = {
                    ...normalizedItem.yearsDifferences,
                    structureNameIt: '',
                };
            }

            return normalizedItem;
        });
    }
    return [];
};

export const normalizeUseFarmOverview = (obs: IOverviewFarm[]) => {
    if (obs && Array.isArray(obs)) {
        return obs.map(item => {
            const normalizedItem = { ...item };

            if (!normalizedItem.year) {
                normalizedItem.year = '';
            }

            return normalizedItem;
        });
    }
    return [];
};

export const normalizeUseSpsOverview = (overviewTableRows: IOverviewSps[][]): IOverviewSps[][] => {
    if (Array.isArray(overviewTableRows)) {
        return overviewTableRows.map(row =>
            row.map(item => {
                const normalizedItem = { ...item };

                if (!normalizedItem.year || typeof normalizedItem.year !== 'string') {
                    normalizedItem.year = '';
                }

                return normalizedItem;
            }),
        );
    }
    return [];
};

export const normalizeUsePatologyOverview = (overviewTableRows: IOverviewPatology[][]): IOverviewPatology[][] => {
    if (Array.isArray(overviewTableRows)) {
        return overviewTableRows.map(row =>
            row.map(item => {
                const normalizedItem = { ...item };

                if (!normalizedItem.year || typeof normalizedItem.year !== 'string') {
                    normalizedItem.year = '';
                }

                return normalizedItem;
            }),
        );
    }
    return [];
};

export const normalizeUseDmOverview = (overviewTableRows: IOverviewDm[][]): IOverviewDm[][] => {
    if (Array.isArray(overviewTableRows)) {
        return overviewTableRows.map(row =>
            row.map(item => {
                const normalizedItem = { ...item };

                if (!normalizedItem.year || typeof normalizedItem.year !== 'string') {
                    normalizedItem.year = '';
                }

                return normalizedItem;
            }),
        );
    }
    return [];
};

export const normalizeUseDiabOverview = (overviewTableRows: IOrderObject): IOrderObject => {
    const normalizedOrderObject: IOrderObject = { ...overviewTableRows };

    Object.keys(normalizedOrderObject).forEach(orderKey => {
        const order = normalizedOrderObject[orderKey];
        order.orderList = order.orderList.map(row =>
            row.map(item => {
                const normalizedItem = { ...item };

                if (!normalizedItem.year || typeof normalizedItem.year !== 'string') {
                    normalizedItem.year = '';
                }

                return normalizedItem;
            }),
        );
    });

    return normalizedOrderObject;
};

export const normalizeUse118Overview = (overviewTableRows: IOverview118[]): IOverview118[] => {
    const normalizedOrderObject: IOverview118[] = [...overviewTableRows];

    return normalizedOrderObject.map(yearData => {
        const normalizedItem = { ...yearData };

        if (!normalizedItem.year || typeof normalizedItem.year !== 'string') {
            normalizedItem.year = '';
        }

        return normalizedItem;
    });
};

export const normalizeUseLaboOverview = (overviewTableRows: IOverviewLabo[]): IOverviewLabo[] => {
    const normalizedOrderObject: IOverviewLabo[] = [...overviewTableRows];

    return normalizedOrderObject.map(yearData => {
        const normalizedItem = { ...yearData };

        if (!normalizedItem.year || typeof normalizedItem.year !== 'string') {
            normalizedItem.year = '';
        }
        return normalizedItem;
    });
};

export const normalizeUseSpaOverview = (overviewTableRows: IOverviewSpa[]): IOverviewSpa[] => {
    const normalizedOrderObject: IOverviewSpa[] = [...overviewTableRows];

    return normalizedOrderObject.map(yearData => {
        const normalizedItem = { ...yearData };

        if (!normalizedItem.year || typeof normalizedItem.year !== 'string') {
            normalizedItem.year = '';
        }
        return normalizedItem;
    });
};

export const normilizeLogs = (objectLogs: LogItem[]) => {
    return objectLogs?.map(l => {
        return {
            ...l,
            targetString: l.actionType + '|' + l.user + '|' + l.action + '|' + l.date + '|' + l.flow,
        };
    });
};
