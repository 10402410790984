import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverviewTableRowSdoSdoR, YearDetail, YearsDifferences } from '../../model';
import i18n, { ELanguages } from '../../localization';

import { EFlowType } from '../../model/enum';
import ExcelSvg from '../Svg/ExcelSvg';
import { IconBtn } from '../Buttons/Buttons';
import { get } from 'lodash';
import { t } from 'i18next';

interface IExportToExcelRecovery {
    overviewTableRows: OverviewTableRowSdoSdoR[];
    flow: EFlowType;
}

const ExportExcelSdoSdoR = (props: IExportToExcelRecovery) => {
    const { overviewTableRows, flow } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = flow ? `${flow.toUpperCase() + '-Overview'}` : 'excel-detail';

    const exportToExcel = () => {
        const customHeadings = overviewTableRows.map(row => {
            const currentYear: YearDetail = get(row, 'currentYearRow', {} as YearDetail);
            const oneYearBackRow: YearDetail = get(row, 'oneYearBackRow', {} as YearDetail);
            const twoYearBackRow: YearDetail = get(row, 'twoYearsBackRow', {} as YearDetail);
            const yearsDifferences: YearsDifferences = get(row, 'yearsDifferences', {} as YearsDifferences);

            const overviewData = [
                {
                    [t('sdoOverview.clmYear')]: currentYear.year,
                    [t('sdoOverview.clmStructureCode')]: currentYear.structureCode,
                    [t('sdoOverview.clmNameStructure')]: i18n.language === ELanguages.it.toString() ? currentYear.structureNameIt : currentYear.structureNameDe,
                    [t('sdoOverview.jan')]: currentYear.jan,
                    [t('sdoOverview.feb')]: currentYear.feb,
                    [t('sdoOverview.mar')]: currentYear.mar,
                    [t('sdoOverview.apr')]: currentYear.apr,
                    [t('sdoOverview.may')]: currentYear.may,
                    [t('sdoOverview.jun')]: currentYear.jun,
                    [t('sdoOverview.jul')]: currentYear.jul,
                    [t('sdoOverview.aug')]: currentYear.aug,
                    [t('sdoOverview.sep')]: currentYear.sep,
                    [t('sdoOverview.oct')]: currentYear.oct,
                    [t('sdoOverview.nov')]: currentYear.nov,
                    [t('sdoOverview.dec')]: currentYear.dec,
                    [t('sdoOverview.clmTotal')]: currentYear.total,
                },
                {
                    [t('sdoOverview.clmYear')]: oneYearBackRow.year,
                    [t('sdoOverview.clmStructureCode')]: oneYearBackRow.structureCode,
                    [t('sdoOverview.clmNameStructure')]: i18n.language === ELanguages.it.toString() ? oneYearBackRow.structureNameIt : oneYearBackRow.structureNameDe,
                    [t('sdoOverview.jan')]: oneYearBackRow.jan,
                    [t('sdoOverview.feb')]: oneYearBackRow.feb,
                    [t('sdoOverview.mar')]: oneYearBackRow.mar,
                    [t('sdoOverview.apr')]: oneYearBackRow.apr,
                    [t('sdoOverview.may')]: oneYearBackRow.may,
                    [t('sdoOverview.jun')]: oneYearBackRow.jun,
                    [t('sdoOverview.jul')]: oneYearBackRow.jul,
                    [t('sdoOverview.aug')]: oneYearBackRow.aug,
                    [t('sdoOverview.sep')]: oneYearBackRow.sep,
                    [t('sdoOverview.oct')]: oneYearBackRow.oct,
                    [t('sdoOverview.nov')]: oneYearBackRow.nov,
                    [t('sdoOverview.dec')]: oneYearBackRow.dec,
                    [t('sdoOverview.clmTotal')]: oneYearBackRow.total,
                },
                {
                    [t('sdoOverview.clmYear')]: twoYearBackRow.year,
                    [t('sdoOverview.clmStructureCode')]: twoYearBackRow.structureCode,
                    [t('sdoOverview.clmNameStructure')]: i18n.language === ELanguages.it.toString() ? twoYearBackRow.structureNameIt : twoYearBackRow.structureNameDe,
                    [t('sdoOverview.jan')]: twoYearBackRow.jan,
                    [t('sdoOverview.feb')]: twoYearBackRow.feb,
                    [t('sdoOverview.mar')]: twoYearBackRow.mar,
                    [t('sdoOverview.apr')]: twoYearBackRow.apr,
                    [t('sdoOverview.may')]: twoYearBackRow.may,
                    [t('sdoOverview.jun')]: twoYearBackRow.jun,
                    [t('sdoOverview.jul')]: twoYearBackRow.jul,
                    [t('sdoOverview.aug')]: twoYearBackRow.aug,
                    [t('sdoOverview.sep')]: twoYearBackRow.sep,
                    [t('sdoOverview.oct')]: twoYearBackRow.oct,
                    [t('sdoOverview.nov')]: twoYearBackRow.nov,
                    [t('sdoOverview.dec')]: twoYearBackRow.dec,
                    [t('sdoOverview.clmTotal')]: twoYearBackRow.total,
                },
                {
                    [t('sdoOverview.clmYear')]: `${oneYearBackRow.year} / ${twoYearBackRow.year}`,
                    [t('sdoOverview.clmStructureCode')]: yearsDifferences.structureCode,
                    [t('sdoOverview.clmNameStructure')]: i18n.language === ELanguages.it.toString() ? yearsDifferences.structureNameIt : yearsDifferences.structureNameDe,
                    [t('sdoOverview.jan')]: yearsDifferences.jan,
                    [t('sdoOverview.feb')]: yearsDifferences.feb,
                    [t('sdoOverview.mar')]: yearsDifferences.mar,
                    [t('sdoOverview.apr')]: yearsDifferences.apr,
                    [t('sdoOverview.may')]: yearsDifferences.may,
                    [t('sdoOverview.jun')]: yearsDifferences.jun,
                    [t('sdoOverview.jul')]: yearsDifferences.jul,
                    [t('sdoOverview.aug')]: yearsDifferences.aug,
                    [t('sdoOverview.sep')]: yearsDifferences.sep,
                    [t('sdoOverview.oct')]: yearsDifferences.oct,
                    [t('sdoOverview.nov')]: yearsDifferences.nov,
                    [t('sdoOverview.dec')]: yearsDifferences.dec,
                    [t('sdoOverview.clmTotal')]: yearsDifferences.total,
                },
            ];

            return overviewData;
        });

        const ws = XLSX.utils.json_to_sheet(customHeadings.flat());
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('generic.downloadExcel', { flow: flow.toUpperCase() })}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};
export default ExportExcelSdoSdoR;
