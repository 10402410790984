import { Container, PageTitle } from '../../style';
import { IActionRoleBE, IActionValueBE, IFlowsWithActionBE } from '../../model';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Btn } from '../../componets/Buttons/Buttons';
import CustomCheckbox from '../../componets/CustomCheckbox/CustomCheckbox';
import { ELanguages } from '../../localization';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import { LoggedUserContext } from '../../App';
import Table from 'react-bootstrap/Table';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import toast from 'react-hot-toast';
import { useRoleById } from '../../api/fetch';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface IEditRoleProps {}

const EditRole = (props: IEditRoleProps) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const { role, isLoading, isError, isValidating } = useRoleById(id);
    const { loggedUser } = useContext(LoggedUserContext);
    const { trigger } = useSWRMutation('Role/Edit', httpClient.postRequest);
    const [actionsToEdit, setActionsToEdit] = useState({});
    const [actions, setActions] = useState<IActionRoleBE[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCheckbox, setLoadingCheckbox] = useState<boolean>(true);

    let flows: IFlowsWithActionBE[] = useMemo(() => {
        return !loadingCheckbox ? get(role, 'flowsWithActions', []) : [];
    }, [role, loadingCheckbox]);

    useEffect(() => {
        if (!!role?.actions) {
            setActions(role.actions);
        }
    }, [role]);

    useEffect(() => {
        setLoadingCheckbox(true);
        setTimeout(() => setLoadingCheckbox(false), 1000);
    }, []);

    useEffect(() => {
        if (!!id) {
            const checked = flows.reduce((acc, data) => {
                data.actionValues.forEach(av => {
                    acc[`${data.flowId}${av.actionId}${id}`] = {
                        roleId: parseInt(id),
                        isChecked: av.isSelected,
                        flowId: data.flowId,
                        actionId: av.actionId,
                    };
                });
                return acc;
            }, {} as any);

            setActionsToEdit(checked);
        }
    }, [flows, id, isValidating]);

    return (
        <Container>
            <BreadCrumb
                paths={[
                    { nameToShow: t('routes.roles'), url: '/ruoli' },
                    { nameToShow: t('roles.edit'), url: '' },
                ]}
            />

            {(isLoading || loadingCheckbox || isValidating) && <Loading />}
            {isError && <GenericError />}
            {role && !loadingCheckbox && !isValidating && (
                <>
                    <PageTitle>
                        {t('roles.edit')} {i18n.language === ELanguages.it ? role.roleNameIt : role.roleNameDe}
                    </PageTitle>
                    <Table size='xl' responsive='xl'>
                        <thead>
                            <tr>
                                <th>{t('routes.flows')}</th>
                                {actions.map((a: IActionRoleBE) => (i18n.language === 'it' ? <th key={a.actionId}>{a.actionNameIt}</th> : <th key={a.actionId}>{a.actionNameDe}</th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(actionsToEdit).length > 0 &&
                                !!id &&
                                flows.map((data: IFlowsWithActionBE, index) => (
                                    <tr key={`${data.flowId}`}>
                                        <td>{data.flowNameIt}</td>
                                        {data.actionValues.map((av: IActionValueBE) => (
                                            <td key={av.actionId}>
                                                <CustomCheckbox
                                                    value={get(actionsToEdit, `[${data.flowId}${av.actionId}${id}].isChecked`, false)}
                                                    onChange={e => {
                                                        if (!!id) {
                                                            switch (av.actionId) {
                                                                case 5:
                                                                    setActionsToEdit({
                                                                        ...actionsToEdit,
                                                                        [`${data.flowId}${5}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: e.target.checked,
                                                                            flowId: data.flowId,
                                                                            actionId: av.actionId,
                                                                        },
                                                                        [`${data.flowId}${6}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: false,
                                                                            flowId: data.flowId,
                                                                            actionId: 6,
                                                                        },
                                                                        [`${data.flowId}${7}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: false,
                                                                            flowId: data.flowId,
                                                                            actionId: 7,
                                                                        },
                                                                    });
                                                                    break;
                                                                case 6:
                                                                    setActionsToEdit({
                                                                        ...actionsToEdit,
                                                                        [`${data.flowId}${5}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: false,
                                                                            flowId: data.flowId,
                                                                            actionId: 5,
                                                                        },
                                                                        [`${data.flowId}${6}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: e.target.checked,
                                                                            flowId: data.flowId,
                                                                            actionId: av.actionId,
                                                                        },
                                                                    });
                                                                    break;
                                                                case 7:
                                                                    setActionsToEdit({
                                                                        ...actionsToEdit,
                                                                        [`${data.flowId}${5}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: false,
                                                                            flowId: data.flowId,
                                                                            actionId: 5,
                                                                        },
                                                                        [`${data.flowId}${7}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: e.target.checked,
                                                                            flowId: data.flowId,
                                                                            actionId: av.actionId,
                                                                        },
                                                                    });
                                                                    break;
                                                                default:
                                                                    setActionsToEdit({
                                                                        ...actionsToEdit,
                                                                        [`${data.flowId}${av.actionId}${id}`]: {
                                                                            roleId: parseInt(id),
                                                                            isChecked: e.target.checked,
                                                                            flowId: data.flowId,
                                                                            actionId: av.actionId,
                                                                        },
                                                                    });
                                                                    break;
                                                            }
                                                        }
                                                    }}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    <div>
                        <Btn
                            text={t('btns.confirm')}
                            onClick={e => {
                                e.preventDefault();
                                setLoading(true);
                                const propertyValues = Object.values(actionsToEdit);
                                toast.promise(trigger({ actions: propertyValues, userId: loggedUser.userId }), {
                                    loading: t('generic.editOingoing'),
                                    success: data => {
                                        setLoading(false);
                                        navigate(`/${route.roles}`);
                                        return t('generic.success');
                                    },
                                    error: e => {
                                        setLoading(false);
                                        return t('generic.error');
                                    },
                                });
                            }}
                            disabled={loading || !Object.values(actionsToEdit).length}
                        />
                    </div>
                </>
            )}
        </Container>
    );
};

export default EditRole;
