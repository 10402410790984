import React from 'react';
import styled from 'styled-components';

interface CustomDatepickerProps {
    label: string;
    name: string;
    value: string;
    onChanging: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
}

const CustomDatepicker = ({ label, name, value, onChanging, error }: CustomDatepickerProps) => (
    <div className='form-group'>
        <label className='active' htmlFor={name}>
            {label}
        </label>
        <input type='date' id={name} name={name} value={value} onChange={onChanging} className={`form-control ${error ? 'is-invalid' : ''}`} />
        {error && <ErrorText>{error}</ErrorText>}
    </div>
);
const ErrorText = styled.div`
    color: red;
    font-size: 0.875rem;
`;

export default CustomDatepicker;
