import { RightTd, RightTh, TableRow } from '../../style';
import i18n, { ELanguages } from '../../localization';

import { EFlowType } from '../../model/enum';
import ExportExcelSdoSdoR from '../ExportExcel/ExportExcelSdoSdoR';
import { Fragment } from 'react';
import GenericError from '../GenericError/GenericError';
import { IOverviewSdoSdoRWithPermissions } from '../../model';
import Loading from '../Loading/Loading';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IOverviewTableSdoSdoRProps {
    overview: IOverviewSdoSdoRWithPermissions | undefined;
    isLoading: boolean;
    isError: any;
    flow: EFlowType;
}

const OverviewTableSdoSdoR = (props: IOverviewTableSdoSdoRProps) => {
    const { overview, isError, isLoading, flow } = props;
    const { t } = useTranslation();

    const clms = [
        { name: t('sdoOverview.clmYear'), id: 1 },
        { name: t('sdoOverview.clmStructureCode'), id: 2 },
        { name: t('sdoOverview.clmNameStructure'), id: 3 },
        { name: t('sdoOverview.jan'), id: 4 },
        { name: t('sdoOverview.feb'), id: 5 },
        { name: t('sdoOverview.mar'), id: 6 },
        { name: t('sdoOverview.apr'), id: 7 },
        { name: t('sdoOverview.may'), id: 8 },
        { name: t('sdoOverview.jun'), id: 9 },
        { name: t('sdoOverview.jul'), id: 10 },
        { name: t('sdoOverview.aug'), id: 11 },
        { name: t('sdoOverview.sep'), id: 12 },
        { name: t('sdoOverview.oct'), id: 13 },
        { name: t('sdoOverview.nov'), id: 14 },
        { name: t('sdoOverview.dec'), id: 15 },
        { name: t('sdoOverview.clmTotal'), id: 16 },
    ];

    return (
        <Fragment>
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {overview && (
                <div>
                    {overview.permissionSet.canExportOverview ? <ExportExcelSdoSdoR flow={flow} overviewTableRows={overview.overviewTableRows} /> : <></>}
                    <div className='tableFixHead'>
                        <Table size='xl'>
                            <thead className='sticky'>
                                <tr className='sticky'>
                                    {clms.map((item, index) => {
                                        if (index === 0) {
                                            return (
                                                <th key={item.id} className={'table-dark'}>
                                                    {item.name}
                                                </th>
                                            );
                                        } else {
                                            return (
                                                <RightTh key={item.id} className={'table-dark'}>
                                                    {item.name}
                                                </RightTh>
                                            );
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {overview.overviewTableRows.map((data, index) => (
                                    <Fragment key={index}>
                                        <TableRow>
                                            <td>{data.currentYearRow.year}</td>
                                            <RightTd>{data.currentYearRow.structureCode}</RightTd>
                                            <RightTd>{i18n.language === ELanguages.it.toString() ? data.currentYearRow.structureNameIt : data.currentYearRow.structureNameDe}</RightTd>
                                            <RightTd>{data.currentYearRow.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.currentYearRow.total.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                        <TableRow>
                                            <td>{data.oneYearBackRow.year}</td>
                                            <RightTd>{data.oneYearBackRow.structureCode}</RightTd>
                                            <RightTd>{i18n.language === ELanguages.it.toString() ? data.oneYearBackRow.structureNameIt : data.oneYearBackRow.structureNameDe}</RightTd>
                                            <RightTd>{data.oneYearBackRow.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oneYearBackRow.total.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                        <TableRow>
                                            <td>{data.twoYearsBackRow.year}</td>
                                            <RightTd>{data.twoYearsBackRow.structureCode}</RightTd>
                                            <RightTd>{i18n.language === ELanguages.it.toString() ? data.twoYearsBackRow.structureNameIt : data.twoYearsBackRow.structureNameDe}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.twoYearsBackRow.total.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                        <tr style={{ borderBottom: '3px solid black' }}>
                                            <td>{`${data.oneYearBackRow.year} / ${data.twoYearsBackRow.year}`}</td>
                                            <RightTd>{data.yearsDifferences.structureCode}</RightTd>
                                            <RightTd>{i18n.language === ELanguages.it.toString() ? data.yearsDifferences.structureNameIt : data.yearsDifferences.structureNameDe}</RightTd>
                                            <RightTd>{data.yearsDifferences.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.yearsDifferences.total}</RightTd>
                                        </tr>
                                    </Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default OverviewTableSdoSdoR;
