import * as Yup from 'yup';

import Select, { MultiValue, PropsValue } from 'react-select';
import { getApplicationLogs, useFlows } from '../../api/fetch';
import { useEffect, useState } from 'react';

import { Btn } from '../Buttons/Buttons';
import CustomDatepicker from '../Datepicker/CustomDatepicker';
import { ELanguages } from '../../localization';
import GenericError from '../GenericError/GenericError';
import Loading from '../Loading/Loading';
import TextFileButton from '../Buttons/TextFileButton';
import styled from 'styled-components';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
    startDate: Yup.date().required(t('logs.startDateRequired')).typeError(t('logs.invalidDate')),
    finishDate: Yup.date().required(t('logs.finishDateRequired')).typeError(t('logs.invalidDate')).min(Yup.ref('startDate'), t('logs.finishDateAfterStart')),
});

const LogPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [dates, setDates] = useState<{ startDate: string; finishDate: string }>({
        startDate: '',
        finishDate: '',
    });
    const [errors, setErrors] = useState<{ startDate?: string; finishDate?: string }>({});
    const [isVisibleFilters, setIsVisibleFilters] = useState<boolean>(false);
    const [selectedFlows, setSelectedFlows] = useState<
        PropsValue<{
            value: string;
            label: string;
        }>
    >([]);
    const { i18n } = useTranslation();

    const toISOString = (dateString: string, hour: number = 0) => {
        if (dateString) {
            const dateValue = new Date(dateString);
            // this is necessary for matching the BE features
            dateValue.setUTCHours(hour, 0, 0, 0);
            const isoValue = dateValue.toISOString();
            return isoValue;
        }
        return '';
    };

    const { logs, isError: isErrorLogs, trigger, isMutating } = getApplicationLogs(i18n.language, toISOString(dates.startDate), toISOString(dates.finishDate, 23));
    const { flows, isLoading: isLoadingFlows, isError: isErrorFlows } = useFlows();

    useEffect(() => {
        if (isVisibleFilters) {
            trigger();
        }
    }, [i18n.language]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        if (name === 'startDate') {
            const nextDates = { ...dates, startDate: value };
            setDates(nextDates);
        } else {
            const nextDates = { ...dates, finishDate: value };
            setDates(nextDates);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            await validationSchema.validate(dates, { abortEarly: false });
            setErrors({});
            trigger();
            setIsVisibleFilters(true);
            setSelectedFlows([]);
            setSearchQuery('');
        } catch (validationErrors) {
            const formattedErrors: { [key: string]: string } = {};
            if (validationErrors instanceof Yup.ValidationError) {
                validationErrors.inner.forEach(error => {
                    if (error.path) {
                        formattedErrors[error.path] = error.message;
                        console.log(error.message);
                    }
                });
            }
            setErrors(formattedErrors);
        }
    };

    const checkInclusion = (input: string, target: string): boolean[] => {
        const splittedInput = input.split(' ');
        const splittedTarget = target.split('|');

        let isPresent: boolean[] = Array(splittedInput.length).fill(false);

        splittedInput.forEach((sInp, index) => {
            const found = splittedTarget.some(sTarget => sTarget.includes(sInp));
            isPresent[index] = found;
        });
        return isPresent;
    };

    const filteredData = logs?.filter(item => {
        const result = checkInclusion(searchQuery.trim().toUpperCase(), item.targetString.trim().toUpperCase());
        const passSearch = result.every(value => value === true);

        const flowsSelected = (selectedFlows as { value: string; label: string }[]) || [];
        const passFlow = flowsSelected.length === 0 || flowsSelected.some(flow => flow.label === item.flow);

        return passSearch && passFlow;
    });

    const range = (start: number, end: number, step = 1) => {
        let output = [];

        if (typeof end === 'undefined') {
            end = start;
            start = 0;
        }

        for (let i = start; i < end; i += step) {
            output.push(i);
        }

        return output;
    };

    const handleFlowsChange = (selectedOptions: MultiValue<{ value: string; label: string }>) => {
        const selectedValues = selectedOptions.map(option => ({ value: option.value, label: option.label }));
        setSelectedFlows(selectedValues);
    };

    const downloadFilteredData = () => {
        if (!filteredData || filteredData.length === 0) {
            toast.error(t('logs.noDataToDownload'));
            return;
        }

        // filteredData in una stringa formattata
        const dataString = filteredData
            .map(item =>
                i18n.language === ELanguages.it
                    ? `Azione: ${item.actionType}, Utente: ${item.user}, Azione: ${item.action}, Data: ${item.date}`
                    : `Aktion: ${item.actionType}, Benutzer: ${item.user}, Aktion: ${item.action}, Datum: ${item.date}`,
            )
            .join('\n');
        const blob = new Blob([dataString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'log.txt';

        // Simula un clic sull'elemento <a>
        document.body.appendChild(link);
        link.click();

        // Rimuove l'elemento <a> e revoca l'URL del Blob
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <LogContainer>
            <form onSubmit={handleSubmit}>
                <TextFileButton overlayText={t('logs.downloadLog')} actionMethod={downloadFilteredData} />
                <DatePickerContainer>
                    <CustomDatepicker label={t('logs.startDateLabel')} name='startDate' value={dates.startDate} onChanging={handleChange} error={errors.startDate} />
                    <CustomDatepicker label={t('logs.finishDateLabel')} name='finishDate' value={dates.finishDate} onChanging={handleChange} error={errors.finishDate} />
                </DatePickerContainer>
                <Btn text={t('logs.filterButton')} type='submit' />
                {isErrorLogs && <GenericError />}
                {isMutating && <Loading />}
                <ResultContainer>
                    {!isLoadingFlows && !isMutating && isVisibleFilters ? (
                        <>
                            <div className='select-wrapper' style={{ marginTop: '0.5rem' }}>
                                {!isLoadingFlows && !isErrorFlows && (
                                    <>
                                        <label htmlFor='allowedFlows'>{t('logs.selectFlows')}</label>
                                        <Select
                                            inputId='allowedFlows'
                                            value={selectedFlows}
                                            isMulti
                                            name='allowedFlows'
                                            options={flows.map(flow => ({
                                                value: String(flow.id),
                                                label: i18n.language === ELanguages.it ? flow.nameIt : flow.nameDe,
                                            }))}
                                            onChange={handleFlowsChange}
                                        />
                                    </>
                                )}
                                {isErrorFlows && <GenericError />}
                            </div>
                            <SearchContainer>
                                <SearchInput type='text' placeholder={t('btns.find')} value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
                                <SearchIcon>🔍</SearchIcon>
                            </SearchContainer>
                        </>
                    ) : (
                        <></>
                    )}
                    {filteredData?.length > 0 &&
                        !isMutating &&
                        range(0, filteredData.length).map(n => (
                            <LogItemWrapper key={n}>
                                <strong>{filteredData[n].actionType}</strong>: <em>{filteredData[n].user}</em> {filteredData[n].action} {i18n.language === ELanguages.it ? 'il' : 'am'}{' '}
                                {filteredData[n].date}
                            </LogItemWrapper>
                        ))}
                    {filteredData?.length === 0 && isVisibleFilters && !isLoadingFlows && !isMutating && <NoResults>{t('generic.noResult')}</NoResults>}
                </ResultContainer>
            </form>
        </LogContainer>
    );
};

const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    padding-right: 6rem;
    max-width: 40rem;
`;

const ResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;
`;

const LogContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: left;
    max-width: 20rem;
    margin-top: 0.5rem;
`;

const SearchInput = styled.input`
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
`;

const SearchIcon = styled.span`
    margin-left: 0.5rem;
    font-size: 1.2rem;
`;

const LogItemWrapper = styled.p`
    margin: 0.5rem 0;
`;

const NoResults = styled.div`
    margin-top: 2.5rem;
    font-size: 1rem;
    color: #666;
`;

export default LogPage;
