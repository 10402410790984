import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import CustomSvg from '../Svg/CustomSvg';
import { IconBtn } from './Buttons';
import { OverlayChild } from '../../style';
import React from 'react';
import { styled } from 'styled-components';
import { text } from 'stream/consumers';

interface TextFileButtonProps {
    overlayText: string;
    actionMethod: () => void;
}

const TextFileButton = ({ overlayText, actionMethod }: TextFileButtonProps) => {
    return (
        <TextBtnContainer>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{overlayText}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={actionMethod}>
                        <CustomSvg iconName='it-file-txt' className='icon icon-primary' />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </TextBtnContainer>
    );
};

const TextBtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem;
`;

export default TextFileButton;
