import { EFlowType, EShippingStatus } from '../../model/enum';
import { FileData, IPermissionBe, ShippingDetail } from '../../model';
import { FileNameContainer, FlexCenter, OverlayChild, StyledShippingInfo } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { addLog, downloadFilesWithDelay } from '../../utils';
import { useContext, useMemo, useState } from 'react';

import CustomModal from '../Modal/CustomModal';
import CustomSvg from '../Svg/CustomSvg';
import { DetailStatus } from '../SendFilesSection/DetailStatus';
import { IconBtn } from '../Buttons/Buttons';
import { IconButtonUnderlinedLink } from '../../style/buttons/buttons';
import InfoButton from '../Buttons/InfoButton';
import LogFileButton from '../Buttons/LogFileButton';
import { LoggedUserContext } from '../../App';
import { ShippingContext } from '../SendFilesSection/SendFilesSdo';
import { format } from 'date-fns';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface IUploadTableProps {
    permissions: IPermissionBe;
    refreshShippings: () => void;
}

export const ITEMSPERPAGE = 10;
const UploadTable = (props: IUploadTableProps) => {
    const { t } = useTranslation();
    const { loggedUser } = useContext(LoggedUserContext);
    const { permissions, refreshShippings } = props;
    const [show, setShow] = useState<{ confirm: boolean; cancel: boolean; shippingId: number | undefined }>({ confirm: false, cancel: false, shippingId: undefined });

    const { trigger: triggerConfirm } = useSWRMutation('Sdo/ShippingConfirm', httpClient.postRequest);
    const { trigger: triggerCancel } = useSWRMutation('Sdo/CancellationRequest', httpClient.postRequest);
    const { itemOffset, shippings, freezedShippings: allShippings, setShippings: setTotal, setFreezedShippings } = useContext(ShippingContext);

    const handleConfirmShipping = (shippingId: number | undefined) => {
        toast.promise(triggerConfirm({ shippingId: shippingId, userId: loggedUser.userId }), {
            loading: t('generic.editOingoing'),
            success: response => {
                setTotal(
                    shippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.ImportRequest,
                            } as any;
                        }
                        return t;
                    }),
                );
                setFreezedShippings(
                    allShippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.ImportRequest,
                            } as any;
                        }
                        return t;
                    }),
                );
                return t('generic.success');
            },
            error: e => {
                return t('generic.error');
            },
        });
        setShow({ confirm: false, cancel: false, shippingId: undefined });
    };

    const handleCancelShipping = (shippingId: number | undefined) => {
        toast.promise(triggerCancel({ shippingId: shippingId }), {
            loading: t('generic.editOingoing'),
            success: response => {
                setTotal(
                    shippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.CancellationRequest,
                            } as any;
                        }
                        return t;
                    }),
                );
                setFreezedShippings(
                    allShippings.map(t => {
                        if (t.id === shippingId) {
                            return {
                                ...t,
                                shippingStatus: EShippingStatus.CancellationRequest,
                            } as any;
                        }
                        return t;
                    }),
                );
                return t('generic.success');
            },
            error: e => {
                return t('generic.error');
            },
        });
        setShow({ confirm: false, cancel: false, shippingId: undefined });
    };

    const displayDate = (fileName: string): string => {
        let parts = fileName.split('_');
        let time = parts[2];
        let hours = time.substring(0, 2);
        let minutes = time.substring(2, 4);
        let formattedTime = hours + ':' + minutes;
        return formattedTime;
    };

    const getFilesPathsArray = (shippingDetail: ShippingDetail) => {
        let ana = get(shippingDetail, 'fileAnaPath', '');
        let cli = get(shippingDetail, 'fileCliPath', '');

        const array: FileData[] = [
            { name: ana.split('/').pop() || 'unknown', url: ana },
            { name: cli.split('/').pop() || 'unknown', url: cli },
        ];
        return array;
    };

    const endOffset = useMemo(() => itemOffset + ITEMSPERPAGE, [itemOffset]);

    const currentItems = useMemo(() => {
        const mapFromShipping = new Map(shippings.map(s => [s.id, s]));
        const uniqueShippings = [...mapFromShipping.values()];
        return uniqueShippings.slice(itemOffset, endOffset);
    }, [itemOffset, endOffset, shippings]);

    return (
        <>
            {currentItems &&
                currentItems.length > 0 &&
                currentItems.map(dataShipping => (
                    <tbody key={dataShipping.id}>
                        <tr key={dataShipping.id}>
                            <td>{`${format(new Date(dataShipping.receiptDate), 'dd/MM/yyyy')} - ${displayDate(get(dataShipping, 'fileNameAnag', ''))}`}</td>
                            <td>
                                <FileNameContainer>
                                    {[dataShipping.fileNameAnag, dataShipping.fileNameClin].filter(Boolean).map((fileName, index) => (
                                        <div key={index}>{fileName}</div>
                                    ))}
                                </FileNameContainer>
                            </td>
                            <td>
                                <StyledShippingInfo>
                                    {permissions.canViewIconFiles && dataShipping.id > 0 && (
                                        <InfoButton shippingId={dataShipping.id} filesNames={[get(dataShipping, 'fileNameAnag', ''), get(dataShipping, 'fileNameClin', '')]}></InfoButton>
                                    )}

                                    {permissions.canDownloadFiles && dataShipping.id > 0 ? (
                                        <OverlayTrigger
                                            placement='top'
                                            transition={false}
                                            i18nIsDynamicList
                                            overlay={
                                                <Tooltip>
                                                    <div>{get(dataShipping, 'fileNameAnag', '')} </div>
                                                    {get(dataShipping, 'fileNameClin', '')}
                                                </Tooltip>
                                            }>
                                            <IconButtonUnderlinedLink
                                                type='button'
                                                onClick={async () => {
                                                    const filesPathArray = getFilesPathsArray(dataShipping);
                                                    await downloadFilesWithDelay(filesPathArray);
                                                    await addLog(dataShipping.id, loggedUser.userName, loggedUser.userSurname, EFlowType.Sdo.toString().toUpperCase());
                                                }}>
                                                {dataShipping.id}
                                            </IconButtonUnderlinedLink>
                                        </OverlayTrigger>
                                    ) : (
                                        <>{dataShipping.id > 0 ? dataShipping.id : ''}</>
                                    )}
                                </StyledShippingInfo>
                            </td>
                            <td>{dataShipping.userName}</td>
                            <td>{`${dataShipping.hospitalizationsCount}`}</td>
                            <td>{dataShipping.shippingStatus === EShippingStatus.ShippingLoaded ? '-' : `${dataShipping.hospitalizationsErrors}`}</td>
                            <td>{dataShipping.shippingStatus === EShippingStatus.ShippingLoaded ? '-' : `${dataShipping.successRatio}%`}</td>

                            <td>
                                {dataShipping.id > 0 && (
                                    <LogFileButton
                                        isSdo={true}
                                        successRatio={dataShipping.successRatio}
                                        permissions={permissions}
                                        logFileName={get(dataShipping, 'logFileName', '')}
                                        id={dataShipping.id}
                                        refreshShippings={refreshShippings}
                                        loggedUser={loggedUser}
                                    />
                                )}
                            </td>

                            <td>
                                <DetailStatus status={dataShipping.shippingStatus} isQuequed={dataShipping.queued} />
                            </td>
                            <td>{dataShipping.drg ? t('generic.yes') : t('generic.no')}</td>
                            <td>
                                {dataShipping.successRatio > 0 && !!dataShipping.shippingStatus && dataShipping.shippingStatus === EShippingStatus.CheckControlsPerformed && permissions.canConfirm && (
                                    <OverlayTrigger
                                        key={`success-${dataShipping.id}`}
                                        transition={false}
                                        i18nIsDynamicList
                                        placement='top'
                                        overlay={<Tooltip id={`success-${dataShipping.id}`}>{t(`sdoOverview.confirm`)}</Tooltip>}>
                                        <FlexCenter>
                                            <IconBtn
                                                withOutline
                                                borderProps={'1px solid #008055'}
                                                onClick={() => {
                                                    setShow({ confirm: true, cancel: false, shippingId: dataShipping.id });
                                                }}>
                                                <CustomSvg iconName='it-check' className='icon icon-success' />
                                            </IconBtn>
                                        </FlexCenter>
                                    </OverlayTrigger>
                                )}
                                {dataShipping.shippingStatus === EShippingStatus.ImportRequest && <FlexCenter>{t(`sdoOverview.work`)}</FlexCenter>}
                                {dataShipping.shippingStatus === EShippingStatus.CancellationRequest && <FlexCenter>{t('sdoOverview.canceling')}</FlexCenter>}
                            </td>
                            <td>
                                {!!dataShipping.shippingStatus && dataShipping.shippingStatus === EShippingStatus.CheckControlsPerformed && permissions.canDelete && (
                                    <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip id={`cancel-${dataShipping.id}`}>{t(`sdoOverview.cancel`)}</Tooltip>}>
                                        <OverlayChild>
                                            <IconBtn
                                                withOutline
                                                borderProps={'1px solid red'}
                                                onClick={() => {
                                                    setShow({ confirm: false, cancel: true, shippingId: dataShipping.id });
                                                }}>
                                                <CustomSvg iconName='it-close' className='icon icon-danger' />
                                            </IconBtn>
                                        </OverlayChild>
                                    </OverlayTrigger>
                                )}
                            </td>
                        </tr>
                    </tbody>
                ))}
            <CustomModal
                key={`confirmModal ${show.shippingId}`}
                title={t('modals.confirmShippingTitle')}
                description={`${t('modals.confirmShipping')} ${show.shippingId} ?`}
                handleConfirm={() => handleConfirmShipping(show.shippingId)}
                show={show.confirm}
                handleClose={() => {
                    setShow({ confirm: false, cancel: false, shippingId: undefined });
                }}
            />
            <CustomModal
                key={`cancelModal ${show.shippingId}`}
                title={t('modals.deleteShippingTitle')}
                description={`${t('modals.deleteShipping')} ${show.shippingId} ?`}
                handleConfirm={() => handleCancelShipping(show.shippingId)}
                show={show.cancel}
                handleClose={() => {
                    setShow({ confirm: false, cancel: false, shippingId: undefined });
                }}
            />
        </>
    );
};

export default UploadTable;
