import { Container, ContainerAccordion, CustomAccordionBody, FlexCenter } from '../../style';
import { useContext, useEffect, useState } from 'react';
import { useSdoOverview, useSdoROverview } from '../../api/fetch';

import { Accordion } from 'react-bootstrap';
import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { EFlowType } from '../../model/enum';
import { IShowModal } from '../../model';
import { LoggedUserContext } from '../../App';
import OverviewTableSdoSdoR from '../../componets/OverviewTable/OverviewTableSdoSdoR';
import { UnderlinedBtn } from '../../componets/Buttons/Buttons';
import XmlTable from '../../componets/Table/XmlTable';
import { isSiag } from '../../utils';
import { route } from '../../route';
import { useTranslation } from 'react-i18next';

export const emptyModal: IShowModal = {
    show: false,
    year: 0,
    month: 0,
    cancelled: false,
};

interface IOverviewSdoSdorProps {}
const OverviewSdoSdoR = (props: IOverviewSdoSdorProps) => {
    const [tab, setTab] = useState<number>(1);
    const { t } = useTranslation();
    const { loggedUser } = useContext(LoggedUserContext);
    const dataSdo = useSdoOverview(loggedUser.userId);
    const dataSdoR = useSdoROverview(loggedUser.userId);
    const isSiagUser = isSiag(loggedUser.role);
    const [viewSdo, setViewSdo] = useState<boolean>();
    const [viewSdoR, setViewSdoR] = useState<boolean>();

    useEffect(() => {
        if (!isSiagUser) {
            const sdoFlow = loggedUser.allowedFlows.find(x => x.name === 'SDO');
            const sdoRFlow = loggedUser.allowedFlows.find(x => x.name === 'SDO-R');
            setViewSdo(sdoFlow ? sdoFlow.isAllowed : false);
            setViewSdoR(sdoRFlow ? sdoRFlow.isAllowed : false);
        } else {
            setViewSdo(true);
            setViewSdoR(true);
        }
    }, []);

    return (
        <>
            {viewSdo && viewSdoR && (
                <>
                    <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: route.overview }]} />
                    <ContainerAccordion>
                        <Accordion defaultActiveKey='sdo' flush>
                            <Accordion.Item eventKey='sdo'>
                                <Accordion.Header onClick={() => setTab(1)}>SDO</Accordion.Header>
                                <CustomAccordionBody>
                                    <FlexCenter className='d-flex'>
                                        <UnderlinedBtn
                                            text={t('routes.overview')}
                                            className={tab === 1 ? 'active' : ''}
                                            onClick={() => {
                                                setTab(1);
                                            }}
                                        />
                                        <UnderlinedBtn
                                            text={t('ministry.sendingToTheMinistry')}
                                            className={tab === 2 ? 'active' : ''}
                                            onClick={() => {
                                                setTab(2);
                                            }}
                                        />
                                    </FlexCenter>
                                    {tab === 1 && <OverviewTableSdoSdoR {...dataSdo} flow={EFlowType.Sdo}></OverviewTableSdoSdoR>}
                                    {tab === 2 && <XmlTable {...dataSdo} flow={EFlowType.Sdo}></XmlTable>}
                                </CustomAccordionBody>
                            </Accordion.Item>
                            <Accordion.Item eventKey='sdor'>
                                <Accordion.Header onClick={() => setTab(3)}>SDO-R</Accordion.Header>
                                <CustomAccordionBody>
                                    <FlexCenter className='d-flex'>
                                        <UnderlinedBtn
                                            text={t('routes.overview')}
                                            className={tab === 3 ? 'active' : ''}
                                            onClick={() => {
                                                setTab(3);
                                            }}
                                        />
                                        <UnderlinedBtn
                                            text={t('ministry.sendingToTheMinistry')}
                                            className={tab === 4 ? 'active' : ''}
                                            onClick={() => {
                                                setTab(4);
                                            }}
                                        />
                                    </FlexCenter>
                                    {tab === 3 && <OverviewTableSdoSdoR {...dataSdoR} flow={EFlowType.Sdor}></OverviewTableSdoSdoR>}
                                    {tab === 4 && <XmlTable {...dataSdoR} flow={EFlowType.Sdor}></XmlTable>}
                                </CustomAccordionBody>
                            </Accordion.Item>
                        </Accordion>
                    </ContainerAccordion>
                </>
            )}

            {viewSdo && !viewSdoR && (
                <Container>
                    <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: route.overview }]} />
                    <FlexCenter className='d-flex'>
                        <UnderlinedBtn
                            text={t('routes.overview')}
                            className={tab === 1 ? 'active' : ''}
                            onClick={() => {
                                setTab(1);
                            }}
                        />
                        <UnderlinedBtn
                            text={t('ministry.sendingToTheMinistry')}
                            className={tab === 2 ? 'active' : ''}
                            onClick={() => {
                                setTab(2);
                            }}
                        />
                    </FlexCenter>
                    {tab === 1 && <OverviewTableSdoSdoR {...dataSdo} flow={EFlowType.Sdo}></OverviewTableSdoSdoR>}
                    {tab === 2 && <XmlTable {...dataSdo} flow={EFlowType.Sdo}></XmlTable>}
                </Container>
            )}

            {!viewSdo && viewSdoR && (
                <Container>
                    <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: route.overview }]} />
                    <FlexCenter className='d-flex'>
                        <UnderlinedBtn
                            text={t('routes.overview')}
                            className={tab === 1 ? 'active' : ''}
                            onClick={() => {
                                setTab(1);
                            }}
                        />
                        <UnderlinedBtn
                            text={t('ministry.sendingToTheMinistry')}
                            className={tab === 2 ? 'active' : ''}
                            onClick={() => {
                                setTab(2);
                            }}
                        />
                    </FlexCenter>
                    {tab === 1 && <OverviewTableSdoSdoR {...dataSdoR} flow={EFlowType.Sdor}></OverviewTableSdoSdoR>}
                    {tab === 2 && <XmlTable {...dataSdoR} flow={EFlowType.Sdor}></XmlTable>}
                </Container>
            )}
        </>
    );
};

export default OverviewSdoSdoR;
