import { Container, ContainerForm } from '../../style';
import { useNavigate, useParams } from 'react-router-dom';

import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Btn } from '../../componets/Buttons/Buttons';
import { Formik } from 'formik';
import { IEditStructureBE } from '../../model';
import { LoggedUserContext } from '../../App';
import { TextInput } from '../../componets/Input/Input';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import toast from 'react-hot-toast';
import { useContext } from 'react';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface INewStructureProps {
    structureToEdit?: IEditStructureBE;
}

const NewStructures = (props: INewStructureProps) => {
    const { t } = useTranslation();
    const { structureToEdit } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const initialStructure = {
        structureNameDe: '',
        structureNameIt: '',
        structureCode: '',
        structureId: 0,
    };
    const { trigger: triggerEdit } = useSWRMutation('Structures/Edit', httpClient.putRequest);

    const { trigger: triggerCreate } = useSWRMutation('Structures/Create', httpClient.postRequest);

    return (
        <Container>
            <BreadCrumb
                paths={[
                    { nameToShow: t('routes.structures'), url: '/' + route.structures },
                    { nameToShow: !!structureToEdit ? t('structures.edit') : t('structures.new'), url: '' },
                ]}
            />
            <Formik
                initialValues={
                    structureToEdit
                        ? {
                              structureNameDe: structureToEdit.nameDe,
                              structureNameIt: structureToEdit.nameIt,
                              structureCode: structureToEdit.code,
                              structureId: structureToEdit.structureId,
                          }
                        : initialStructure
                }
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    toast.promise(structureToEdit ? triggerEdit({ ...values, structureId: parseInt(id ?? '0'), userId: loggedUser.userId }) : triggerCreate({ ...values, userId: loggedUser.userId }), {
                        loading: structureToEdit ? t('generic.editOingoing') : t('generic.createOingoing'),
                        success: data => {
                            setSubmitting(false);
                            navigate('/' + route.structures);
                            return t('generic.success');
                        },
                        error: e => {
                            setSubmitting(false);
                            const text = get(e, 'response.data', '');
                            return !!text && text.includes('already exists') ? t('structures.codeAlreadyExist') : t('generic.error');
                        },
                    });
                }}>
                {({ isSubmitting, handleSubmit }) => (
                    <ContainerForm onSubmit={handleSubmit}>
                        <TextInput name='structureNameIt' label={t('structures.structureNameIt')} required />
                        <TextInput name='structureNameDe' label={t('structures.structureNameDe')} />
                        <TextInput readOnly={!!structureToEdit} name='structureCode' label={t('structures.code')} required />

                        <Btn text={!!structureToEdit ? t('btns.edit') : t('btns.create')} type='submit' disabled={isSubmitting} />
                    </ContainerForm>
                )}
            </Formik>
        </Container>
    );
};

export default NewStructures;
