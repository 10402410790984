import { HeaderThProps, TableProps } from '../model';

import { Accordion } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { styled } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & .input-number-add,
    .input-number-sub {
        display: none;
    }
`;

export const StyledShippingInfo = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

export const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const PageTitle = styled.h1`
    font-size: 28px;
    line-height: 55px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #191919;
    margin: 0;
`;
export const PageDescription = styled.p`
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #191919;
    margin: 0;
`;

export const RowInputsWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    & .form-group,
    .select-wrapper {
        min-width: 50%;
    }
    & div {
        min-width: 50%;
    }
`;
export const ToastWrapper = styled.div`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
`;

export const FileInfoBox = styled.div`
    width: 100%;
    display: flex;
    padding: 1.5rem;
    height: max-content;
    align-items: center;
    background-color: #f2f7fc;
    gap: 4rem;
    overflow-x: auto;
    overflow-y: hidden;
`;

export const FormatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: center;
    & p {
        color: #003366;
        font-size: 1rem;
        margin-bottom: 0;
    }
    & div {
        font-weight: 600;
        color: #003366;
        font-size: 1rem;
    }
`;
export const FormContainer = styled.form`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    & div {
        width: 33%;
    }
`;

export const ExlBtnWrapper = styled.div`
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
`;

export const ContainerForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 0;
    width: 100%;
    padding: 0 2rem;
    & .form-group,
    .select-wrapper {
        min-width: 80%;
        margin-bottom: 0;
    }
    & button {
        max-width: 12rem;
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 0;
    width: 100%;
    padding: 0 2rem;
    & .form-group {
        margin-bottom: 0px;
    }
    & button {
        max-width: 12rem;
    }
`;
export const FilterSeparatorForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    & button {
        width: fit-content;
    }
`;

export const OverlayChild = styled.div`
    display: flex;
    width: fit-content;
`;

export const MyPaginate = styled(ReactPaginate).attrs({
    activeClassName: 'active', // default to "selected"
})`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    color: #06c;
    gap: 0.2rem;
    li a {
        border-radius: 4px;
        padding: 0.1rem 1rem;
        border: #06c 1px solid;
        cursor: pointer;
        text-decoration: none;
        color: #06c;
    }
    li.previous a,
    li.next a,
    li.break a {
        border-color: transparent;
    }
    li.active a {
        background-color: #06c;
        border: #06c 1px solid;
        border-color: transparent;
        color: white;
        border-radius: 4px;
    }
    li.disabled a {
        color: grey;
    }
    li.disable,
    li.disabled a {
        cursor: default;
        color: #06c;
    }
`;

export const HeaderTh = styled.th.withConfig({
    shouldForwardProp: prop => prop !== 'isCustomDE',
})<HeaderThProps>`
    & p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: normal;
        margin: 0;
        ${({ isCustomDE }) =>
            isCustomDE &&
            `
                white-space: normal; 
                word-wrap: break-word; 
                word-break: break-word;
            `}
    }

    ${({ isCustomDE }) =>
        isCustomDE &&
        `
            max-width: 4rem;
            text-align: left; 
            white-space: normal; 
            overflow-wrap: break-word; 
            overflow: visible; 
        `}
`;

export const Div = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const StyledTable = styled.div.withConfig({
    shouldForwardProp: prop => prop !== 'isCustomIT',
})<TableProps>`
    ${({ isCustomIT }) =>
        isCustomIT &&
        `   height: 42rem;
        `}
    display: flex;
    width: 100%;
    overflow-y: auto;
    flex-direction: column;
`;

export const CustomAccordionBody = styled(Accordion.Body)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const CustomAccordionFilters = styled(Accordion)`
    display: flex;
    flex-direction: column;
    border: 0px solid #c5c7c9 !important;
    border-bottom: 1px solid #c5c7c9 !important ;
`;

export const ContainerAccordion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: stretch;

    & .input-number-add,
    .input-number-sub {
        display: none;
    }
`;

export const StyledLi = styled.li`
    width: 70%;
`;
export const StyledLabel = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #06c;
    border-color: #06c;
    padding: 0.75rem 1.5rem !important;
    width: 10rem;
    font-size: 1rem !important;
    justify-content: flex-start;
    & svg {
        margin: 0;
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.2rem !important;
    }
`;
export const StyledUpload = styled.button`
    padding: 0.75rem 1.5rem !important;
    width: 10rem;
    font-size: 1rem !important;
    justify-content: flex-start;
`;
export const StyledFilesList = styled.div`
    display: flex;
    vertical-align: middle;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: #cc334d;
    & span {
        align-items: center;
        vertical-align: middle;
        max-width: 375px;
        margin-bottom: 1rem;
        margin-left: 1rem;
    }
`;

export const RowTd = styled.td`
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;
    vertical-align: middle;
    margin: 0;
`;

export const RightTd = styled.td`
    text-align: right;
`;

export const InfoStyledBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #fff;

    &:hover {
        opacity: 0.8;
    }
`;

export const StyledSvg = styled.svg`
    padding: 2px;
`;

export const RightTh = styled.th`
    text-align: right;
`;

export const CustomDivOverview = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const ContainerNoGap = styled.div`
    gap: '0';
`;

export const TableRow = styled.tr`
    background: #f0f0f0;
`;

export const DivFlexRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

export const FileNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    white-space: nowrap;
`;
